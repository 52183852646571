import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Frame from "../../components/frame"
import Layout from "../../components/layout"
import Text from "../../components/text"

const Page = () => {
  return (
    <Layout>
      <Frame width="100vw" align="center">
        <Frame
          maxWidth={1000}
          width="100vw"
          direction="vertical"
          align="center"
          gap={4}
          px={1}
          pt={4}
          pb={8}
        >
          {/* Header */}
          <Frame maxWidth={800} align="center" gap={2}>
            <Text>November 7th, 2022</Text>
            <Text
              size="60px"
              weight={300}
              color="#006FB7"
              align="center"
              lineHeight={1}
            >
              Local not-for-profit tackles world’s deadliest cancer
            </Text>
          </Frame>
          {/* Body */}
          <StaticImage
            src="https://res.cloudinary.com/dgmfkwhdi/image/upload/v1667729782/ct.jpg"
            alt=""
            style={{ flex: 1 }}
            objectFit="contain"
          />
          <Frame maxWidth={700} gap={1.2}>
            <Text size="18px" lineHeight={1.5}>
              <p>
                A local not-for-profit is beginning its fight against lung
                cancer with a one-minute evidence-based quiz to help Australians
                know their level of risk.
              </p>
              <p>
                Lungscreen Foundation is now seeking the assistance of local GPs
                and health providers to encourage patients to take the quiz.
              </p>
              <p>
                The foundation, based in Buderim, has launched its free quiz to
                coincide with lung cancer awareness month.
              </p>
              <p>
                People receive a risk assessment as soon as they finish the
                quiz. If they’re at a high risk of lung cancer, they’re
                encouraged to see their GP.
              </p>
              <p>
                To make screening referrals effortless for GPs, the quiz site
                enables participants with high risk factors or significant
                symptoms to register and download a pre-filled referral form.
              </p>
              <p>
                The GP can then use the pre-filled form to refer the patient to
                Lungscreen for a lung scan and a detailed report.
              </p>
              <p>
                Presently, there is no national lung screening program, unlike
                screening programs for bowel, breast and prostate.
              </p>
              <p>
                Lungscreen Foundation CEO, Dr Siavash Es’haghi, said he started
                the foundation to drive attention to the urgent need for a
                national lung screening program.
              </p>
              <p>
                “Lung screening saves lives because it can pick up the early
                signs of cancer,” he said.
              </p>
              <p>
                “Medical evidence clearly shows that the earlier lung cancer is
                detected, the better the chances of survival.
              </p>
              <p>
                “We have the skills and technologies to make a huge difference
                to the way lung cancer is detected and we’re determined to be at
                the forefront of change here in Australia.”
              </p>
              <p>
                Right now, national lung screening programs are being offered in
                USA, China and across Europe while New Zealand is piloting a
                screening program.
              </p>
              <p>
                Dr Es’haghi said Lungscreen Foundation takes seriously its role
                to reduce stigma around lung cancer being “a smoker’s disease”.
              </p>
              <p>
                “Many don’t realise that lung cancer kills more people than all
                the other cancers—and that everyone’s at risk, even if they
                don’t smoke.”
              </p>
              <p>One in five people with lung cancer has never smoked.</p>
              <p>
                Lung cancer kills up to 10,000 Australians every year, and more
                than 170,000 Australians will be diagnosed with lung cancer over
                the next decade.
              </p>
              <p>
                Lungscreen Foundation is a not-for-profit organisation with all
                earnings funnelled back to life-saving programs such as lung
                cancer research, education, quit smoking programs and funding
                for lung cancer nurses.
              </p>
              <p>
                <Link to="/to-quiz">Click here to take the quiz</Link>
              </p>
            </Text>
          </Frame>
        </Frame>
      </Frame>
    </Layout>
  )
}

export default Page
